import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styles from './Footer.module.css'

const FooterLinks = (props: any) => {
	const router = useRouter()

	return (
		<>
			<div className={`footer-about-links ${styles.aboutm} w-full sm:w-1/2 lg:w-1/4 mt-2 sm:mt-0 ml-0 sm:ml-5 md:ml-6 lg:ml-6`}>
				<div className='col-title text-black font-bold mt-6 aboutus'>About Us</div>
				<div className='link-wrapper mt-4 sm:mt-8 flex flex-col'>
					{router.asPath === '/about-us' ? (
						<span className='text-gray-600 w-max relative cursor-pointer'>About Us </span>
					) : (
						<Link href={'/about-us'}>
							<a onClick={() => props.setIsLoading(true)} className='text-gray-600 w-max relative'>
								About Us{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/our-mission' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Our Mission </span>
					) : (
						<Link href={'/our-mission'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Our Mission{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/faqs' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>FAQs </span>
					) : (
						<Link href={'/faqs'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								FAQs{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/privacy-policy' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Privacy Policy </span>
					) : (
						<Link href={'/privacy-policy'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Privacy Policy{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/terms-and-conditions' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Terms &amp; Conditions </span>
					) : (
						<Link href={'/terms-and-conditions'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Terms &amp; Conditions{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/contact' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Contact Us </span>
					) : (
						<Link href={'/contact'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								{' '}
								Contact Us
							</a>
						</Link>
					)}
				</div>
			</div>
			<div className='footer-information-links w-full sm:w-1/2 lg:w-1/4 '>
				<div className='col-title text-black font-bold mt-8 sm:mt-6 '>Our Information</div>
				<div className='link-wrapper mt-4 sm:mt-8 flex flex-col'>
					{router.asPath === '/special-discount-offers' ? (
						<span className=' text-gray-600 w-max relative cursor-pointer'>Special Discount Offers </span>
					) : (
						<Link href={'/special-discount-offers'}>
							<a className=' text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								Special Discount Offers{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/trade-shows' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'> Trade Shows</span>
					) : (
						<Link href={'/trade-shows'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								{' '}
								Trade Shows
							</a>
						</Link>
					)}

					{router.asPath === '/show-special/greater-ny-dental-meeting' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Show Special</span>
					) : (
						<Link href={'/show-special/greater-ny-dental-meeting'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								{' '}
								Show Special
							</a>
						</Link>
					)}

					{router.asPath === '/blog' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'> Blogs</span>
					) : (
						<Link href={'/blog'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								Blogs{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/downloads' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Downoads </span>
					) : (
						<Link href={'/downloads'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props?.setIsLoading(true)}>
								Downloads{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/videos' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Videos </span>
					) : (
						<Link href={'/videos'}>
							<a className='mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Videos{' '}
							</a>
						</Link>
					)}
					<a target='_blank' href='/GerDentUSA - General IFU.pdf' className='mt-4 text-gray-600 w-max relative'>
						Instructions For Use
					</a>
				</div>
			</div>
			<div className='footer-support-links hidden lg:block lg:w-1/4'>
				<div className='col-title text-black font-bold mt-6'>Customer Care</div>
				<div className='link-wrapper mt-8 flex flex-col'>
					{router.asPath === '/payment-info' ? (
						<span className=' text-gray-600 w-max relative cursor-pointer'>Payment Info </span>
					) : (
						<Link href={'/payment-info'}>
							<a className=' text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Payment Info{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/shipping-information' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Shipping Information </span>
					) : (
						<Link href={'/shipping-information'}>
							<a className=' mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Shipping Information
							</a>
						</Link>
					)}

					{router.asPath === '/warranty' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Warranty </span>
					) : (
						<Link href={'/warranty'}>
							<a className=' mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Warranty
							</a>
						</Link>
					)}

					{router.asPath === '/return-policy' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Return Policy </span>
					) : (
						<Link href={'/return-policy'}>
							<a className=' mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Return Policy
							</a>
						</Link>
					)}

					{router.asPath === '/returns-refunds-and-exchanges' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Shipping &amp; Return </span>
					) : (
						<Link href={'/returns-refunds-and-exchanges'}>
							<a className=' mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Shipping &amp; Return{' '}
							</a>
						</Link>
					)}

					{router.asPath === '/repair-products' ? (
						<span className='mt-4 text-gray-600 w-max relative cursor-pointer'>Repair Products </span>
					) : (
						<Link href={'/repair-products'}>
							<a className=' mt-4 text-gray-600 w-max relative' onClick={() => props.setIsLoading(true)}>
								Repair Products
							</a>
						</Link>
					)}
				</div>
			</div>
		</>
	)
}

export default FooterLinks
