export const BASE_URL = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? process.env.NEXT_PUBLIC_DEV_BASE_URL : process.env.NEXT_PUBLIC_LIVE_BASE_URL
export const SITE_URL = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? process.env.NEXT_PUBLIC_DEV_BASE_URL : process.env.NEXT_PUBLIC_LIVE_BASE_URL

// export const API_BASE_URL = BASE_URL + "frontend/apis/";
export const API_BASE_URL = BASE_URL + 'api/'
export const ImagePath = 'https://imagedelivery.net/jeGPDt_KxC7-jKA5fSxddA/'

export const imgApiUrl = {
	homeSliderBanner: `${BASE_URL}up_data/banners`,
	products: {
		thumbnail: `${BASE_URL}up_data/products/images/thumbnails`,
		medium: `${BASE_URL}up_data/products/images/medium`,
		large: `${BASE_URL}up_data/products/images/large`,
		videos: {
			thumbnail: `${BASE_URL}up_data/products/videos/thumbnails`,

			images: `${BASE_URL}up_data/products/videos/image`
		},
		video: `${BASE_URL}up_data/products/videos`,

		files: `${BASE_URL}up_data/products/file`
	},
	categories: {
		thumbnail: `${BASE_URL}up_data/categories/thumbnails`,
		medium: `${BASE_URL}up_data/categories/medium`,
		large: `${BASE_URL}up_data/categories/larg`
	},
	blog: `${BASE_URL}up_data/blog`
}
