import React, { useState, useEffect, useContext } from 'react'
import Link from 'next/link'
import { FaArrowCircleUp } from 'react-icons/fa'
import Style from './Footer.module.css'
import Image from 'next/image'
import Payment from '../../public/assets/img/payment-img.png'
import { MainContext } from '../../contexts/MainContext'

const BottomFooter = () => {
	const [isVisible, setIsVisible] = useState(false)
	const { setIsLoading } = useContext(MainContext)
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 1000) {
				setIsVisible(true)
			} else {
				setIsVisible(false)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	return (
		<div className='bg-white'>
			<div className={`${Style.copyRightWrapper} width  w-full  text-sm text-center mt-10 h-20 flex items-center justify-between mb-24 ${Style.copyRightBottom} `}>
				<div className={`copy-right ${Style.mbcopyright} margin-btm  `}>
					Copyright © 2024
					<span>
						&nbsp;
						<Link href='/'>
							<a onClick={() => setIsLoading(true)}>
								<span className='cursor-pointer border-b border-black border-solid'>GerDentUSA.com.</span>
							</a>
						</Link>
						&nbsp;
						<br className='block sm:hidden' />
					</span>
					All Right Reserved.
				</div>
				<div className={` paymentImgfooter  mt-1`}>
					<Image src={Payment} alt='payment' width={282} height={44} />
				</div>
				<FaArrowCircleUp
					className={`${Style.scrollTop}`}
					onClick={scrollTop}
					style={{
						height: 40,
						bottom: '13rem',
						right: '1rem',
						zIndex: '9',
						display: isVisible ? 'flex' : 'none'
					}}
				/>
			</div>
		</div>
	)
}

export default BottomFooter
