import { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../contexts/MainContext'
import BottomFooter from './BottomFooter'
import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'
import NewsletterForm from '../../pages/api/newsletter/NewsletterForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { MessageContext } from '../../contexts/MessageContext'
import { LiteLoader } from '../CustomElements/Loader/Loader'
import style from './Footer.module.css'

const Footer = () => {
	const { isLoading, setIsLoading } = useContext(MainContext)
	const { setAlert } = useContext(MessageContext)

	const [loading, setLoading] = useState(false)

	interface IFormInputs {
		email: string
	}

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.required('Email is required')
			.email('Invalid email')
			.matches(/^[A-Z0-9.]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, 'Invalid email')
	})

	const formOptions = { resolver: yupResolver(validationSchema) }

	const gsapFunc = async () => {
		const gsap = (await import('gsap')).default
		gsap?.timeline().fromTo(
			'.alert-show',
			{
				xPercent: -50,
				autoAlpha: 0
			},
			{
				xPercent: -15,
				ease: 'back(2)',
				autoAlpha: 1,
				onComplete: () => {
					gsap?.to('.alert-show', {
						xPercent: -50,
						autoAlpha: 0,
						duration: 0.3,
						delay: 2
					})
				}
			},
			'<90%'
		)
	}

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm(formOptions)

	const onSubmit = async (data: any) => {
		setLoading(true)

		const result: any = await NewsletterForm(data)

		if (result?.data?.status == 1) {
			setAlert({
				type: 'success',
				message: 'Thanks for your subscription!'
			})
			reset()
			await gsapFunc()
		}
		setLoading(false)
	}
	const cursor = loading ? 'cursor-not-allowed' : 'cursor'

	useEffect(() => {})
	return (
		<footer className={`bg-gray-100 pt-5 sm:pt-9 realtive ${isLoading == false ? 'opacity-1-trans' : 'opacity-0'}`}>
			<form className='footer-subscribe-wrapper' onSubmit={handleSubmit((data) => !loading && onSubmit(data))}>
				<div className='width flex flex-col md:flex-row justify-between md:items-center pb-5 sm:pb-9'>
					<p className='text-gray-600 leading-normal'>Subscribe Our Newsletter for the Updates of Your Interest!</p>

					<div className='w-full md:w-[400px] mt-4 md:mt-0 '>
						<div className='flex justify-between'>
							<div></div>
							<small className='text-red-500 '>{errors?.email && <p>{errors?.email?.message}</p>}</small>
						</div>
						<div className={`subscribe-input-wrapper border border-gray-300 border-solid text-sm rounded-lg bg-white flex items-center mt-1 ${errors?.email ? 'border-red-400 focus:border-red-400' : 'border-gray-300'}`}>
							<svg xmlns='http://www.w3.org/2000/svg' className='ml-2 h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#bbb'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' />
							</svg>
							<input className={`p-3 rounded-lg focus:outline-none w-full ${errors?.email ? 'border-red-400' : 'border-gray-300'}`} type='email' placeholder='Enter your email' {...register('email')} />
							<div>
								{loading ? (
									<button
										type='submit'
										className={`${cursor} flex py-3 px-5 w-full text-sm font-medium text-center text-white ${style.subscribeBtn} rounded-lg bg-primary-700 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
									>
										Subscribe
										<LiteLoader loaderType='sml' className='ml-[5px]' />
									</button>
								) : (
									<button
										type='submit'
										className={`py-3 px-5 w-full text-sm font-medium text-center text-white ${style.subscribeBtn} rounded-lg cursor-pointer bg-primary-700 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
									>
										Subscribe
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</form>

			<hr className='hr-dark' />
			<div className='pt-5 md:pt-12'>
				<DesktopFooter setIsLoading={setIsLoading} />
				<MobileFooter />
				<BottomFooter />
			</div>
		</footer>
	)
}

export default Footer
