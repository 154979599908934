import React from 'react'
import styles from './Loader.module.css'

const LiteLoader = (props: any) => {
	return (
		<div className={`${styles.loader_wrapper} ${props?.className}`}>
			<div className={`${styles.lite_loader} ${styles.loader} ${props?.loaderType === 'sml' && styles.sml}`} />
		</div>
	)
}

const DarkLoader = (props: any) => {
	return (
		<div className={`${styles.loader_wrapper} ${props?.alertType !== 'delete' && props?.className}`}>
			<div className={`${styles.dark_loader} ${styles.loader} ${props?.loaderType === 'sml' && styles.sml} ${props?.alertType === 'delete' && props?.className}`} />
		</div>
	)
}

const BlackLoader = (props: any) => {
	return (
		<div className={`${styles.loader_wrapper} ${props?.className}`}>
			<div className={`${styles.black_loader} ${styles.loader} ${props?.loaderType === 'sml' && styles.sml}`} />
		</div>
	)
}

export { LiteLoader, DarkLoader, BlackLoader }
