import Link from 'next/link'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { MainContext } from '../../contexts/MainContext'
import { MessageContext } from '../../contexts/MessageContext'
import NewsletterForm from '../../pages/api/newsletter/NewsletterForm'
import styles from './Footer.module.css'

const MobileFooter = () => {
	interface IFormInputs {
		email: 'string'
	}

	// form validation rules
	const validationSchema = Yup.object().shape({
		email: Yup.string().required('Email is required')
	})
	const formOptions = { resolver: yupResolver(validationSchema) }

	const { setAlert } = useContext(MessageContext)
	const { setIsLoading } = useContext(MainContext)

	const [successMessage, setSuccessMessage] = useState({
		type: '',
		message: ''
	})

	const {
		handleSubmit,
		formState: { errors }
	} = useForm<IFormInputs>(formOptions)
	const gsapFunc = async () => {
		const gsap = (await import('gsap')).default
		gsap?.timeline().fromTo(
			'.alert-show',
			{
				xPercent: -50,
				autoAlpha: 0
			},
			{
				xPercent: 0,
				ease: 'back(2)',
				autoAlpha: 1,
				onComplete: () => {
					gsap?.to('.alert-show', {
						xPercent: -50,
						autoAlpha: 0,
						duration: 0.3,
						delay: 2
					})
				}
			},
			'<90%'
		)
	}
	const onSubmit = async (data: IFormInputs) => {
		setIsLoading(true)
		const result: any = await NewsletterForm(data)
		setAlert({
			type: 'success',
			message: 'Thanks for your subscription!'
		})

		await gsapFunc()
		setIsLoading(false)
	}

	return (
		<>
			<form className='footer-subscribe-wrapper  lg:block lg:w-3/12  ' onSubmit={handleSubmit((data) => onSubmit(data))}>
				<div className='footer-subscription-container-mob block lg:hidden   '>
					<div className={`footer-subscription-wrapper-mob flex flex-col sm:flex-row ml-7 ${styles.footerResponsive} `}>
						<div className='footer-support-links w-full w-12/12 sm:w-6/12  '>
							<div className='col-title text-black font-bold mt-8 sm:mt-10'>Customer Care</div>
							<div className='link-wrapper mt-4 sm:mt-8 flex flex-col'>
								<Link href='/shipping-and-returns'>
									<a className='text-gray-600'>Shipping &amp; Return</a>
								</Link>
								<Link href='/privacy-policy'>
									<a className='mt-4 text-gray-600'>Privacy Policy</a>
								</Link>
								<Link href='/terms-and-conditions'>
									<a className='mt-4 text-gray-600'>Terms &amp; Conditions</a>
								</Link>
							</div>
						</div>
						<div className='footer-subscribe-wrapper w-full  sm:w-10/12  pr-4 '>
							<div className='contact-location'>
								<div className=' text-black mt-8 sm:mt-10   font-semibold'>Our Address</div>
								<address className='location-detail flex mt-1'>
									<div className='company-detail text-gray-600 leading-normal text-sm'>61-21 Springfield Blvd, Suite 396 Oakland Gardens, NY 11364 United States</div>
								</address>
								<div className=' text-black mt-4 font-semibold'>Contact Number</div>
								<div className='location-detail flex mt-1'>
									<div className='company-detail text-gray-600 leading-normal text-sm'>
										Office:
										<a href='tel:+15165937100'>(516) 593-7100</a> - <a href='tel:+15168070884'>(516) 807-0884</a>
									</div>
								</div>
								<div className='text-black mt-4 font-semibold'>Email Address</div>
								<div className='location-detail flex mt-1'>
									<div>
										<span className='inline-block company-detail text-gray-600 leading-normal text-sm'>
											Email:
											<span className='primary-blue-color'>
												<a href='mailto:info@gerdentusa.com'>info@gerdentusa.com</a>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className={`alert-message ${successMessage?.type == 'error' ? 'error' : ''}${successMessage?.type == 'success' || successMessage?.type == 'error' ? '' : 'hidden'}`}>{successMessage?.message}</div>
		</>
	)
}

export default MobileFooter
