declare global {
	interface Window {
		dataLayer: any[]
	}
}

export const GTM_ID = 'GTM-M328CR6'

export const pageview = (url: string) => {
	window.dataLayer?.push({
		event: 'pageview',
		page: url
	})
}
