import React from 'react'

const Newsletter = () => {
	return (
		<>
			<form className='footer-subscribe-wrapper hidden lg:block lg:w-3/12 pr-3'>
				<div className='contact-location'>
					<div className=' text-black mt-5 font-semibold'>Our Address</div>
					<address className='location-detail flex mt-1'>
						<div className='company-detail text-gray-600 leading-normal text-sm'>61-21 Springfield Blvd, Suite 396 Oakland Gardens, NY 11364 United States</div>
					</address>
					<div className=' text-black mt-4 font-semibold'>Contact Number</div>
					<div className='location-detail flex mt-1'>
						<div className='company-detail text-gray-600 leading-normal text-sm'>
							Office:
							<a href='tel:+15165937100'>(516) 593-7100</a> - <a href='tel:+15168070884'>(516) 807-0884</a>
						</div>
					</div>
					<div className='text-black mt-4 font-semibold'>Email Address</div>
					<div className='location-detail flex mt-1'>
						<div>
							<span className='inline-block company-detail text-gray-600 leading-normal text-sm'>
								Email:
								<span className='primary-blue-color'>
									<a href='mailto:info@gerdentusa.com'>info@gerdentusa.com</a>
								</span>
							</span>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default Newsletter
