import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Layout from '../components/Layouts'
import { MainContext, MainProvider } from '../contexts/MainContext'
import { SessionProvider } from '../contexts/SessionContext'
import Head from 'next/head'
import { useContext, useEffect } from 'react'
import { pageview } from '../lib/gtm'
import { useRouter } from 'next/router'

function MyApp({ Component, pageProps }: AppProps) {
	const route = useRouter()

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			pageview(url)
		}

		route.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			route.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [route.events])

	return (
		<MainProvider>
			<SessionProvider>
				<Layout>
					<Head>
						<title>GerDentUSA</title>
					</Head>
					<Component {...pageProps} />
				</Layout>
			</SessionProvider>
		</MainProvider>
	)
}

export default MyApp
