import { NextPage } from 'next'
import Footer from './Footer/Footer'
import Script from 'next/script'
import { MainContext } from '../contexts/MainContext'
import { useContext, useEffect, useState } from 'react'
import Nav from './Nav/Nav'
import { MessageProvider } from '../contexts/MessageContext'
import Spin from '../UIComponents/pagination/spinner/spin'
import { SITE_URL } from '../lib/constants'
import { useRouter } from 'next/router'

const Layout: NextPage = ({ children }: any) => {
	const { OpenAllCat, CartOpen, isTopBar, isLoading } = useContext(MainContext)
	const [ncOpen, setNcOpen] = useState(0)
	const [menuRecord, setMenuRecord] = useState<any>([])
	const [visible, setVisible] = useState(false)
	const router = useRouter()

	useEffect(() => {
		const handleRouteChange = () => {
			const liveChatScript = `
      var LHC_API = LHC_API||{};
      LHC_API.args = {mode:'widget',lhc_base_url:'//chat.germedusa.com/index.php/',wheight:450,wwidth:350,pheight:520,pwidth:500,domain:'https://www.dvmcentral.com/',leaveamessage:true,check_messages:false};
      (function() {
      var po = document.createElement('script'); po.type = 'text/javascript'; po.setAttribute('crossorigin','anonymous'); po.async = true;
      var date = new Date();po.src = 'https://chat.germedusa.com/design/defaulttheme/js/widgetv2/index.js?'+(""+date.getFullYear() + date.getMonth() + date.getDate());
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
    })();
`

			const container = document.getElementById('global_live_chat')

			if (container) {
				container.innerHTML = liveChatScript
			}
		}

		router.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router])

	useEffect(() => {
		setTimeout(() => {
			const chatContainer = document.getElementById('lhc_container_v2'),
				chatWrapper = document.getElementById('lhc_status_widget_v2')
			if (chatWrapper !== undefined) {
				chatContainer.style.setProperty('z-index', '2', 'important')
				chatWrapper.style.bottom = '65px'
				chatWrapper.style.right = '0'
				chatWrapper.style.setProperty('z-index', '2', 'important')
			}
		}, 1000)
	}, [])

	useEffect(() => {
		window.addEventListener('load', () => {
			document.body.style.visibility = 'visible'
		})

		const mainManu = async () => {
			try {
				const res = await fetch(`${SITE_URL}api/left-menu`)
				const resData = await res?.json()
				setMenuRecord(resData)
			} catch (error) {
				console.error(error)
			}
		}
		mainManu()
		setVisible(true)
	}, [isTopBar])

	return (
		<>
			{' '}
			<noscript>
				<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-M328CR6' height='0' width='0' style={{ display: 'none', visibility: 'hidden' }}></iframe>
			</noscript>
			<Script
				id='global_live_chat'
				dangerouslySetInnerHTML={{
					__html: `var LHC_API = LHC_API||{};
									LHC_API.args = {mode:'widget',lhc_base_url:'//chat.germedusa.com/index.php/',wheight:450,wwidth:350,pheight:520,pwidth:500,domain:'https://www.dvmcentral.com/',leaveamessage:true,check_messages:false};
									(function() {
									var po = document.createElement('script'); po.type = 'text/javascript'; po.setAttribute('crossorigin','anonymous'); po.async = true;
									var date = new Date();po.src = 'https://chat.germedusa.com/design/defaulttheme/js/widgetv2/index.js?'+(""+date.getFullYear() + date.getMonth() + date.getDate());
									var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
								})();`
				}}
				strategy='beforeInteractive'
			/>
			<div className={`${OpenAllCat == 1 || CartOpen == 1 ? 'body-height' : ''} ${visible == false ? 'hidden' : 'visible'}`}>
				<MessageProvider>
					<Nav ncOpen={ncOpen} setNcOpen={setNcOpen} menuRecord={menuRecord} />
					{isLoading == true && <Spin />}
					<main className={`home-page relative ${isTopBar == 0 ? 'mt-0' : ''} ${isLoading == false ? 'opacity-1-trans' : 'opacity-0'} `}>{children}</main>
					<Footer />
				</MessageProvider>
			</div>
			<Script defer src='/assets/js/nav.js' />
		</>
	)
}

export default Layout
