import Link from 'next/link'
import React from 'react'
interface topbarProps {
	isTopBar: number
	topBarCloseFunc: () => void
}
const TopBar = (props: topbarProps) => {
	return (
		<div className={`top-bar dark-blue-bg text-white text-sm realtive pr-5 sm:pr-0 ${props?.isTopBar == 0 ? 'hidden absolute' : ''} `}>
			<div className='top-bar-wrapper width flex items-center py-2'>
				<div className='welcome-msg text-left sm:flex sm:justify-center w-full'>
					<span className='tbar-text-main mr-2 sm:mr-4 flex flex-wrap'>
						<span className='mr-2'>20% Exclusive discount plus free next day delivery, excludes sale</span>
						<a href={'/shipping-information'} className='font-bold inline-flex sm:inline-block' target='_blank' rel='noreferrer'>
							<span className='border-b-2 border-white border-solid'> Shipping Information </span>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 ml-2 inline' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z' />
							</svg>
						</a>
					</span>
				</div>
			</div>
			<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 absolute right-2 top-2 cursor-pointer top-bar-close' onClick={() => props?.topBarCloseFunc()} fill='none' viewBox='0 0 24 24' stroke='#fff'>
				<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
			</svg>
		</div>
	)
}

export default TopBar
